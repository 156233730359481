.text-blue {
  color: #006EFF;
}

.text-lightblue {
  color: #00E8FF;
}

.text-red {
  color: #FF003D;
}

.text-yellow {
  color: #FFB300;
}

.text-green {
  color: #00B07B;
}

.text-lightgreen {
  color: lighten(#00B07B, 15%);
}

.text-orange {
  color: #FF5400;
}

.text-pink {
  color: #CA2E72;
}

.text-brown {
  color: #8E0D2F;
}
