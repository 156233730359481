.support {
  padding: 40px;
  position: relative;
  overflow: hidden;

  > img {
    position: absolute;
    bottom: -250px;
    right: 0;
  }

  .content {
    padding-right: 200px;
  }
}
