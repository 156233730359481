.drop-shadow {
  -webkit-filter: drop-shadow(4px 4px 25px rgba(0, 0, 0, 0.5));
  filter: url(#drop-shadow);
}

.hover-drop-shadow {
  &:hover {
    -webkit-filter: drop-shadow(4px 4px 25px rgba(0, 0, 0, 0.5));
    filter: url(#drop-shadow);
  }
}
