.featured {
  margin: 15px 0 40px;
  min-height: 350px;
  padding: 50px;
  position: relative;

  h2, h5, p {
    color: #ffffff;
  }

  h2 {
    margin-bottom: 20px;
  }

  h5 {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.71px;
    line-height: 15px;
    text-transform: uppercase;
  }

  p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 12px;
  }

  .content {
    width: 45%;
    z-index: 2;
  }
}
