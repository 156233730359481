.table {
  &.table-hive {
    font-size: 14px;

    thead {
      th {
        border: 0;
        color: #939393;

        &:first-child {
          padding-left: 0;
        }

        > h5 {
          margin: 0;
        }
      }
    }

    tbody {
      tr {
        &:first-child {
          th, td {
            border-top: 0;

            &:first-child {
              border-top-left-radius: 12px;
            }

            &:last-child {
              border-top-right-radius: 12px;
            }
          }
        }

        &:last-child {
          th, td {
            &:first-child {
              border-bottom-left-radius: 12px;
            }

            &:last-child {
              border-bottom-right-radius: 12px;
            }
          }
        }

        th, td {
          background-color: #1f1e20;
          border-color: #262626;
          padding: 14px;
          line-height: 42px;
        }

        th {
          color: #ffffff;
          font-size: 17px;
        }

        td {
          ul.actions {
            float: right;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              float: left;
              padding: 0 15px;
              text-align: center;
            }
          }
        }

        th:first-child,
        th:last-child,
        td:first-child,
        td:last-child {
          padding-left: 35px;
          padding-right: 35px;
        }
      }
    }
  }
}
