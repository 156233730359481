.error-page {
  > .bg {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    z-index: -1;
  }

  > .wrap {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > .content {
      color: #ffffff;
      text-align: center;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);

      h1, h3, h4 {
        color: #ffffff;
        font-size: 9em;
      }

      h1 {
        font-weight: 600;
        margin-bottom: 12px;
      }

      h3 {
        margin-bottom: 30px;
      }

      h4 {
        font-size: 3em;
      }

      p {
        margin-bottom: 20px;
      }
    }
  }
}
