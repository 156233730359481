.form-group {
  background-color: #ffffff;
  border-radius: 3em;
  padding: 15px 30px;
}

.col-form-label {
  position: absolute;
  top: 46px;
  left: 65px;
  color: #999!important;
  font-size: 0.75em;
  z-index: 1;
}

.form-control {
  border: 0;
  color: #000000;
  font-size: 1.35rem;
  margin: 18px 0 0;
  padding: 0;
  height: auto;

  &:focus {
    border: 0;
    box-shadow: none;
    color: #000000;
  }
}
