@keyframes pulse {
  from { transform: scale(1); }
  50% { transform: scale(0.80); }
  to { transform: scale(1); }
}

.loading-overlay {
  background: url('/img/loading.png') no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -79.5px;
  margin-left: -20px;
  height: 159px;
  width: 80px;
  animation: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  > span {
    bottom: -30px;
    width: 100%;
    position: absolute;
    text-align: center;
  }
}

.menu-opened {
  .loading-overlay {
    margin-left: 70px;
  }
}
