.navbar {
  .navbar-brand {
    font-size: 24px;

    svg {
      margin-right: 16px;
    }
  }

  .form-inline {
    .btn {
      margin-left: 10px;
    }
  }

  .btn-circle {
    margin-right: 15px;
  }

  .dropdown-toggle:after {
    display: none;
  }
}
