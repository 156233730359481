$baseBg: #1f1e20 !default;
$baseColor: #929093 !default;
$primaryColor: #ffbb02 !default;
$darkenBg: darken($baseBg, 5%) !default;
$lightenBg: lighten($baseBg, 5%) !default;

$iconBg: $darkenBg !default;
$dropDownBg: $lightenBg !default;

$openedWidth: 220px !default;
$collapsedWidth: 50px !default;

.navigation {
  &, * {
    box-sizing: border-box;
  }

  & {
    background-color: $baseBg;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding-bottom: 50px;
    transition: width 0.3s;
    z-index: 5;

    .logo {
      height: 165px;
      padding: 40px 0;
      text-align: center;

      img {
        transition: width 0.3s;
        width: 72px;
      }
    }

    &.collapsed > .logo {
      img {
        width: 35px;
      }
    }

    > .menu {
      width: 100%;
      height: 100%;
      overflow: hidden auto;

      > .menu-item {
        > .item-link {
          > .item-icon {
            border-radius: 3px;
            line-height: 30px;
            text-align: center;
            height: 30px;
            width: 30px;
          }

          &:after {
            content: '';
            display: block;
            clear: both;
          }
        }

        &.active-item > .item-link,
        &.parent-active-item > .item-link {
          box-shadow: 3px 0 0 0 $primaryColor inset;

          > .item-icon {
            color: $primaryColor;
          }
        }
      }
    }

    &.collapsed > .menu {
      width: calc(100% + 17px);
      padding-right: 17px;
    }
  }

  .menu-collapsed-item {
    background-color: $primaryColor;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .menu-item {
    position: relative;
    display: block;
  }


  .menu-item.active-item,
  .menu-item.parent-active-item {
    > .item-link {
      color: #ffffff;
      font-weight: 300;
    }
  }

  .menu-item.collapsed-item {
    > .item-link {
      color: #000000;

      > .item-icon {
        background-color: transparent;
        color: #000000;
        border-radius: 3px;
        line-height: 30px;
        text-align: center;
        height: 30px;
        width: 30px;
      }
    }
  }

  .item-link {
    position: relative;
    color: $baseColor;
    display: block;
    font-size: 15px;
    font-weight: 300;
    padding: 10px;
    line-height: 30px;
    text-decoration: none;
    z-index: 20;

    &[disabled] {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .item-title {
    display: block;
    white-space: nowrap;
  }

  .item-icon {
    float: left;
    line-height: 30px;
    margin-right: 10px;
  }

  .menu-arrow {
    width: 30px;
    text-align: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.3s;

    &.open-arrow {
      transform: translateY(-50%) rotate(90deg);
    }
  }

  .menu-dropdown {
    > .menu {
      background-color: #1b191c;
      padding: 0;

      .item-link {
        color: $baseColor;
        font-size: 13px;
        padding: 8px 10px;
      }

      .item-link:hover {
        color: #ffffff;
      }

      > .menu-item {
        padding-left: 40px;
      }

      .menu-item.active-item {
        .item-link {
          color: #ffbb02;
        }
      }
    }
  }

  &.opened {
    width: $openedWidth;

    > .menu {
      > .menu-item {
        &.open-item > .item-link {
          color: #ffffff;
          background-color: #1b191c;

          > .item-icon {
            color: #ffffff;
            background-color: #1b191c;
          }
        }
      }
    }

    .item-link:hover {
      color: #ffffff;
      background-color: #1b191c;
    }
  }

  &.collapsed {
    width: $collapsedWidth;

    .menu-dropdown > .menu {
      > .menu-item {
        padding-left: 10px;
      }
    }
  }

  .expand-enter-active,
  .expand-leave-active {
    transition: height 0.35s;
    overflow: hidden;
  }

  .expand-enter,
  .expand-leave-to {
    height: 0 !important;
  }

  .menu-header {
    color: rgba($baseColor, 0.7);
    font-size: 14px;
    font-weight: 300;
    padding: 10px;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .menu-badge {
    padding: 0 6px;
    font-size: 12px;
    border-radius: 3px;
    position: absolute;
    right: 10px;
    height: 20px;
    line-height: 23px;
    margin-top: 5px;
    font-weight: 200;
    text-transform: uppercase;

    .default-badge {
      color: $baseColor;
      background-color: $darkenBg;
    }
  }

  .collapse-btn {
    background-color: $darkenBg;
    color: $baseColor;
    display: block;
    text-align: center;
    position: absolute;
    height: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    border: none;
    outline: none;
    width: 100%;
  }

  .collapsed-items {
    position: absolute;
    width: $openedWidth;
    z-index: 30;

    > .menu-dropdown {
      overflow-y: auto;
      position: absolute;
      right: 0;
      left: $collapsedWidth;
    }
  }
}
