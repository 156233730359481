.screen-manager {
  text-align: center;
  margin-bottom: 40px;

  .icons {
    margin-bottom: 30px;

    .on {
      background: #44340e linear-gradient(to bottom, #44340e 0%, #1f1e20 80%);
      border-top-left-radius: 12px;
    }
    .icon {
      margin: 35px 0 0;

      > img {
        margin-left: -30px;
      }
    }

    .total {
      font-size: 58px;
      font-weight: 500;
      margin-bottom: 0;
    }

    .total-label {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}
