.swal2-popup {
  background: $card-bg;
  -webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);

  .swal2-title {
    color: $white;
  }

  .swal2-content {
    color: $body-color;
  }

  .swal2-styled {
    line-height: 1.5;
    letter-spacing: 1px;
    text-transform: uppercase;

    &.swal2-confirm,
    &.swal2-cancel {
      border-radius: 6.25rem;
      font-size: 0.75rem;
      font-weight: 700;
      padding: 0.71rem 1.625rem 0.7em;
    }

    &.swal2-confirm {
      background-color: $primary;

      &:focus {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    &.swal2-cancel {
      color: $white;
      background-color: $secondary;

      &:focus {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }
}

.swal2-icon {
  &.swal2-warning {
    border-color: $primary;
    color: $primary;
  }
}
