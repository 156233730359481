.btn-power {
  background: url("/img/dashboard/btn_bg.png") no-repeat center center;
  float: left;
  margin-right: 40px;
  height: 119px;
  width: 119px;

  > .icon {
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }

  &.on {
    > .icon {
      background-image: url("/img/dashboard/btn_icon_on.png");

      @media only screen and (-o-min-device-pixel-ratio: 5/4),
      only screen and (-webkit-min-device-pixel-ratio: 1.25),
      only screen and (min--moz-device-pixel-ratio: 1.25),
      only screen and (min-device-pixel-ratio: 1.25),
      only screen and (min-resolution: 1.25dppx) {
        background-image: url("/img/dashboard/btn_icon_on@2x.png")
      }
    }
  }
}
