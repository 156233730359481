.notifications {
  .notification-wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .notification-title {
    font-weight: 600;
  }

  .vue-notification {
    font-size: 12px;
    padding: 10px;
    margin: 0 5px 5px;

    color: white;
    background: #44A4FC;
    border-left: 5px solid #187FE7;

    &.warn {
      background: #ffb648;
      border-left-color: #f48a06;
    }

    &.error {
      background: #E54D42;
      border-left-color: #B82E24;
    }

    &.success {
      background: #68CD86;
      border-left-color: #42A85F;
    }
  }
}
