.modal-open {
  .layout {
    filter: blur(10px);
    transition: all 0.1s;
  }
}

.modal-fullscreen {
  filter: none;

  .modal-backdrop {
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
    transition: all 0.1s;
  }

  .modal-dialog,
  .modal-content {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
  }

  .modal-dialog {
    border-radius: 0;
    margin: 0;
  }

  .modal-content {
    background-color: transparent;
  }

  .modal-header,
  .modal-footer {
    border: 0;
  }

  .modal-header {
    .close {
      background-color: #242224;
      border-radius: 50%;
      color: #ffffff;
      font-size: 55px;
      line-height: 34px;
      margin: 15px;
      opacity: 1;
      height: 60px;
      width: 60px;

      &:not(:disabled):not(.disabled):hover,
      &:not(:disabled):not(.disabled):focus {
        background-color: lighten(#242224, 10%);
        opacity: 1;
      }
    }
  }

  .modal-footer {
    background-color: #171517;
    padding: 1rem 4rem;

    .btn {
      border: 0;
      padding: 1.2em 3em 1.1em 3em;
    }

    .btn-primary {
      background: linear-gradient(to right, #9b49c1 0%, #422391 100%);
      color: #ffffff;
    }
  }

  .modal-title {
    color: #ffffff;
    font-size: 35px;
    line-height: 43px;
    margin: 25px 0 0 20px;
  }
}
