$font-family-sans-serif: "Proxima Nova Lt", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-bg: #171517;
$body-color: #b7b7b7;

$primary: #ffb300;
$secondary: #363537;

$slate-gray: #555555;

$font-weight-normal: 500;

$btn-border-radius: 6.25rem;

$input-btn-font-size: 0.75rem;
$input-btn-padding-y: 0.6rem;
$input-btn-padding-x: 1.625rem;

$card-bg: #1f1e20;
$card-border-radius: 12px;
$card-border-width: 0;

$list-group-bg: #1f1e20;
$list-group-border-radius: 12px;

$navbar-dark-color: $slate-gray;
$dark: rgba(31, 30, 32, 0.9);

$dropdown-color: #ffffff;
$dropdown-bg: lighten($body-bg, 5%);
$dropdown-divider-bg: $body-bg;
$dropdown-link-color: #ffffff;
$dropdown-link-hover-color: #ffffff;
$dropdown-link-hover-bg: lighten($dropdown-bg, 5%);
