.notification {
  background: #1f1d20 linear-gradient(85.13deg, #282728 0%, #1f1e20 100%);
  padding: 25px;
  margin-bottom: 40px;

  .content {
    padding-top: 18px;
  }

  .actions {
    text-align: center;
    line-height: 104px;
  }

  .heading {
    font-weight: 700;
  }
}
