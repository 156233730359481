@import url('https://cdn.jsdelivr.net/npm/font-proxima-nova@1.0.1/style.css');
@import "./variables";
@import "~bootstrap/scss/bootstrap";

@import "bootstrap";
@import "card";
@import "form";
@import "modal";
@import "notifications";
@import "sweet_alert";
@import "table";
@import "utils";
