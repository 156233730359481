@import "./variables.scss";

html, body {
  height: 100%;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $slate-gray;
}

a {
  cursor: pointer;
}

.btn {
  font-weight: 700;
  padding: 0.71rem 1.625rem 0.7em;
  letter-spacing: 1px;
  text-transform: uppercase;

  &.btn-secondary:hover {
    background-color: lighten($slate-gray, 2%);
  }
}

.navbar-dark {
  transition: 500ms ease;
}

.card {
  h1, h2, h3, h4, h5, h6 {
    color: $white;
  }
}

.list-group-item {
  border: 0;
  border-bottom: 1px solid $black;
  margin-bottom: 0;
  padding: 1rem 1.6rem;

  &:last-child {
    border: 0;
  }
}

.btn-circle {
  border-radius: 20px;
  font-size: 12px;
  height: 40px;
  line-height: 2.4;
  padding: 6px 0px;
  text-align: center;
  width: 40px;
}

.app-icon {
  margin-right: 10px;
  width: 35px;
}
